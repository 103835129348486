.box {
    height: 200px;
    width: 200px;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    cursor: pointer;
    filter: grayscale(0%);
    transition: 0.20s ease-in-out;
}

.box:hover {
    transform: scale(1.10);
    /* filter: grayscale(100%);*/
}

.icon {
    display: flex;
    height: 200px;
    width: 200px;
    border-radius: 100px !important;
    overflow: hidden;
    perspective: 1px;
}

.fade-in {
    animation: fadeIn 0.5s;
    animation-fill-mode: both;    
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translate(0px, 10px) scale(1.1);
    }

    100% {
        opacity: 1;
        transform: translate(0px, 0px) scale(1.0);
    }
}